import { NavLink } from "react-router-dom";
import styles from "./Footer.module.css";

function Footer() {
  return (
    <div className={styles.Footer}>
      <div className={styles.FooterWrap}>
        <ul>
          <NavLink to="/">
            <li>Home</li>
          </NavLink>
          <NavLink to="/about">
            <li>About us</li>
          </NavLink>
          <NavLink to="/products">
            <li>Products</li>
          </NavLink>
          <NavLink to="/contact-us">
            <li>Contact us</li>
          </NavLink>
        </ul>
        <ul>
        <a href="mailto: gustafssonpeter766@gmail.com">
          <li>
            {" "}
            <i style={{ paddingRight: "10px" }} className="far fa-envelope" />
            gustafssonpeter766@gmail.com
          </li>
          </a>
          <a href="tel: +1 (971) 444 4158">
            <li>
              <i style={{ paddingRight: "10px" }} className="fas fa-phone" />
              +1 (919) 727-1465
            </li>
          </a>
        </ul>
        <ul>
          <p>
            Gustafsson Holdings is a proud holdings company providing medical
            technology solutions to both public and private health sectors. We
            work with global innovators, developers and manufacturers of medical
            technology devices. These unique relationships brings with it the
            expertise, experience and support of respected global companies, and
            provides the highest level of clinical and technical application
            support.
          </p>
        </ul>
      </div>
      <div className={styles.copyright}>&#169; Gustafsson Holdings</div>
    </div>
  );
}

export default Footer;
