import Product from "./Product/Product";
import styles from "./Products.module.css";

function Products() {
  return (
    <div className={styles.Products}>
      <h2>Products</h2>
      <div className={styles.ProductWrap}>
        <Product equipment="bed" />
        <Product equipment="cardiacultrasound" />
        <Product equipment="ecgmachine" />
        <Product equipment="flowmeter" />
        <Product equipment="multiparameterpatientmonitor" />
        <Product equipment="pulseoximeter" />
        <Product equipment="syringepump" />
        <Product equipment="valleylabforce2machine" />
        <Product equipment="vent" />
        <Product equipment="wheelchair" />
        <Product equipment="xray" />
        <Product equipment="dualflow" />
        <Product equipment="mri" />
        <Product equipment="oxygenconcentrator" />
        <Product equipment="defibrillator" />
        <Product equipment="ultrasound" />
        <Product equipment="anesthesia" />
        <Product equipment="autoclave" />
        <Product equipment="glucosemonitor" />
        <Product equipment="dialysis" />
        <Product equipment="bandage" />
        <Product equipment="physiotherapy" />
        <Product equipment="electrosurgery" />
        <Product equipment="lifesupport" />
        <Product equipment="electrocardiography" />
        <Product equipment="tables" />
        <Product equipment="infusion" />
        <Product equipment="oxygen" />
        <Product equipment="stretcher" />
      </div>
    </div>
  );
}

export default Products;
