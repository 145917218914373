import React from "react";
import styles from './BurgerIcon.module.css'

function BurgerIcon(prop) {
  return (
    <React.Fragment>
      <svg className={styles.Burger} onClick={prop.click}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={24}
        height={24}
        viewBox="0 0 172 172"
        style={{ fill: "#000000", float: 'right', marginRight: '10%', position: 'relative', top: '65px', zIndex: 15 }}
      >
        <g
          fill="none"
          fillRule="nonzero"
          stroke="none"
          strokeWidth={1}
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit={10}
          strokeDasharray="true"
          strokeDashoffset={0}
          fontFamily="none"
          fontWeight="none"
          fontSize="none"
          textAnchor="none"
          style={{ mixBlendMode: "normal" }}
        >
          <path d="M0,172v-172h172v172z" fill="none" />
          <g fill={prop.color}>
            <path d="M0,14.33333v14.33333h172v-14.33333zM0,78.83333v14.33333h172v-14.33333zM0,143.33333v14.33333h172v-14.33333z" />
          </g>
        </g>
      </svg>
    </React.Fragment>
  );
}

export default BurgerIcon;
