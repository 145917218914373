import { useState } from "react";
import { NavLink } from "react-router-dom";
import BurgerIcon from "../UI/BurgerIcon.js";
import styles from "./Header.module.css";
import Menu from "./Menu/Menu";

function Header() {
  let menuDisplay = {
    height: 0,
    opacity: 0,
    color: "currentColor",
    iconColor: "#0066ff",
  };
  const [initialState, finalState] = useState(menuDisplay);

  const toggleMenu = () => {
    if (initialState.height === "0") {
      finalState({
        ...menuDisplay,
        height: "100vh",
        opacity: "1",
        color: "#fff",
        iconColor: "#fff",
      });
    } else {
      finalState({
        ...menuDisplay,
        height: "0",
        opacity: "0",
        color: "currentColor",
        iconColor: "#0066ff",
      });
    }
  };
  return (
    <header className={styles.Header}>
      <div
        style={{ color: initialState.color, zIndex: 100, position: "absolute" }}
        className={styles.logo}
      >
        Gustafsson Holdings
      </div>
      <nav>
        <ul>
          <NavLink to="/">
            <li>Home</li>
          </NavLink>
          <NavLink to="/about">
            <li>About us</li>
          </NavLink>
          <NavLink to="/products">
            <li>Products</li>
          </NavLink>
          <NavLink to="/contact-us">
          <li>Contact us</li>
          </NavLink>
        </ul>
      </nav>
      <BurgerIcon color={initialState.iconColor} click={toggleMenu} />
      <Menu
        menuDisplay={{
          height: initialState.height,
          opacity: initialState.opacity,
          click: toggleMenu,
        }}
      />
    </header>
  );
}

export default Header;
