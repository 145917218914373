import Button from "../UI/Button/Button";
import styles from "./Home.module.css";
import image from "../../assets/images/break.png";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className={styles.Home}>
      <section>
        <div className={styles.sectionWrap}>
          <div className={styles.sectionTitle}>
            Medical Equipment at best quality and price <br />
            <Link to="/products">
              <Button secondary>View our products</Button>
            </Link>
          </div>
        </div>
      </section>
      <div className={styles.BrandDescription}>
        <img src={image} alt="no-alt" />
        <Fade>
          <h2>Our Values</h2>
          <p>
            We are dedicated to providing our customers with
            <span> superior medical technology solutions </span>(equipment &
            services), to enable them achieve a sustainable, profitable and
            excellent healthcare delivery. Our success has been based on three
            solid foundations of Quality, Value and Support.
          </p>
        </Fade>
        <Fade>
          <h2>Our Team</h2>
          <p>
            We maintain a<span> highly dynamic and experienced team</span>. We
            promote our principals’ products in the public and private health
            facilities across the globe. We pride ourselves in understanding our
            customers’ needs and ensuring that our products and services are
            tailored to meet their requirements. Our people take pride in
            delivering on our promise to our customers.
          </p>
        </Fade>
      </div>
    </div>
  );
}

export default Home;
