import styles from "./Button.module.css";

function Button(props) {
  return (
    <button
      style={{
        background: props.secondary ? "rgb(33, 172, 177)" : "rgb(0, 102, 255)",
      }}
      className={styles.Button}
    >
      {props.children}
    </button>
  );
}

export default Button;
