import { NavLink } from "react-router-dom";
import styles from "./Menu.module.css";

function Menu(prop) {
  return (
    <div style={{ height: prop.menuDisplay.height }} className={styles.Menu}>
      <nav style={{ opacity: prop.menuDisplay.opacity }}>
        <ul>
          <NavLink onClick={prop.menuDisplay.click} to="/">
            <li>Home</li>
          </NavLink>
          <NavLink onClick={prop.menuDisplay.click} to="/about">
            <li>About us</li>
          </NavLink>
          <NavLink onClick={prop.menuDisplay.click} to="/products">
            <li>Products</li>
          </NavLink>
          <NavLink onClick={prop.menuDisplay.click} to="/contact-us">
          <li>Contact us</li>
          </NavLink>
        </ul>
      </nav>
    </div>
  );
}

export default Menu;
