import './App.css';
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import Footer from './Components/Footer/Footer';
import Products from './Components/Products/Products';
import ContactUs from './Components/ContactUs/ContactUs';

function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <Header />
      <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/products" element={<Products/>} />
      <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
      <Footer />
    </div>
    </BrowserRouter>
  );
}

export default App;
