import styles from "./Product.module.css";
import ecgmachine from "../../../assets/images/ecgmachine.jpg";
import bed from "../../../assets/images/bed.jpg";
import cardiacultrasound from "../../../assets/images/cardiacultrasound.jpg";
import flowmeter from "../../../assets/images/flowmeter.jpg";
import multiparameterpatientmonitor from "../../../assets/images/multiparameterpatientmonitor.jpg";
import pulseoximeter from "../../../assets/images/pulseoximeter.jpg";
import syringepump from "../../../assets/images/syringepump.jpg";
import valleylabforce2machine from "../../../assets/images/valleylabforce2machine.jpg";
import vent from "../../../assets/images/vent.jpg";
import wheelchair from "../../../assets/images/wheelchair.jpg";
import stretcher from "../../../assets/images/stretcher.jpeg"
import defibrillators from "../../../assets/images/defibrillators.jpg";
import oxygenconcentrator from "../../../assets/images/oxygenconcentrator.jpg";
import dualflow from "../../../assets/images/dualflow.jpg";
import ultrasound from "../../../assets/images/ultrasound.jpg";
import xray from "../../../assets/images/xray.jpg";
import Button from "../../UI/Button/Button";
import Fade from "react-reveal/Fade";
import mrimachine from "../../../assets/images/mrimachine.jpeg";
import anesthesia from "../../../assets/images/anesthesia.webp";
import autoclave from "../../../assets/images/autoclave.jpeg";
import glucosemonitor from "../../../assets/images/glucosemonitor.jpeg";
import dialysis from "../../../assets/images/dialysis.jpeg";
import bandage from "../../../assets/images/bandage.jpeg";
import physiotherapy from "../../../assets/images/physiotherapy.png";
import electrosurgery from "../../../assets/images/electrosurgery.jpeg";
import lifesupport from "../../../assets/images/lifesupport.jpeg";
import electrocardiography from "../../../assets/images/electrocardiography.jpeg";
import tables from "../../../assets/images/tables.png";
import infusion from "../../../assets/images/infusion.webp";
import oxygen from "../../../assets/images/oxygen.webp";
import { Link } from "react-router-dom";

function Product(props) {
  let description;
  let title;
  let image;

  switch (props.equipment) {
    case "bed":
      description = "Fully refurbished hospital bed";
      title = "Hospital bed";
      image = bed;
      break;
    case "cardiacultrasound":
      description =
        "Refurbished cardiac ultrasound machine colour Doppler made in the USA";
      title = "Cardiac Ultrasound";
      image = cardiacultrasound;
      break;
    case "ecgmachine":
      description = "Refurbished ECG machine 12-channel";
      title = "ECG machine";
      image = ecgmachine;
      break;
    case "flowmeter":
      description = "New flow meter wall-mount (green)";
      title = "flow meter";
      image = flowmeter;
      break;
    case "multiparameterpatientmonitor":
      description =
        "New multi para meters patient monitor in this monitor spo2, PR, nibp price with battery backup";
      title = "Multiparamter patient monitor";
      image = multiparameterpatientmonitor;
      break;
    case "pulseoximeter":
      description = "New battery operated pulse oximeter";
      title = "pulse oximeter";
      image = pulseoximeter;
      break;
    case "syringepump":
      description = "Refurbished syringe pump";
      title = "Syringe pump";
      image = syringepump;
      break;
    case "valleylabforce2machine":
      description = "Refurbished valleylab force 2 machine without accessories";
      title = "Valleylab force 2 machine";
      image = valleylabforce2machine;
      break;
    case "vent":
      description = "Refurbished vent";
      title = "vent";
      image = vent;
      break;
    case "wheelchair":
      description = "New foldable manual wheelchair";
      title = "Wheelchair";
      image = wheelchair;
      break;
    case "defibrillator":
      description = "Refurbish defibrillators";
      title = "Defibrillators";
      image = defibrillators;
      break;
    case "oxygenconcentrator":
      description = "New 5 liter oxygen concentrator";
      title = "Oxygen Concentrator";
      image = oxygenconcentrator;
      break;
    case "xray":
      description = "New double x ray viewer";
      title = "Xray";
      image = xray;
      break;
    case "ultrasound":
      description = "Refurbished ultrasound machine with two probes: convex and liner";
      title = "Ultrasound";
      image = ultrasound;
      break;
    case "dualflow":
      description = "New dual flow meter for wall point";
      title = "Dual Flow";
      image = dualflow;
      break;
    case "mri":
      description = "High-tech medical imaging machine that uses a powerful magnetic field to produce detailed images of the body's internal structures.";
      title = "MRI Machine";
      image = mrimachine;
      break;
    case "anesthesia":
      description = "Advanced medical device used to administer anesthesia to patients undergoing surgery or other medical procedures.";
      title = "Anesthesia Machine";
      image = anesthesia;
      break;
    case "autoclave":
      description = "Medical sterilization equipment used to sterilize surgical instruments and other materials.";
      title = "Autoclave sterilizer";
      image = autoclave;
      break;
    case "glucosemonitor":
      description = "Device used to measure the concentration of glucose in the blood, commonly used by diabetic patients.";
      title = "Blood Glucose Monitor";
      image = glucosemonitor;
      break;
    case "dialysis":
      description = "Medical device used to remove waste and excess fluids from the blood of patients with kidney failure.";
      title = "Dialysis Machine";
      image = dialysis;
      break;
    case "bandage":
      description = "Medical dressing used to protect, support, and compress wounds during the healing process.";
      title = "Bandages";
      image = bandage;
      break;
    case "physiotherapy":
      description = "Equipment used in physiotherapy treatments to help patients recover from injuries and improve their physical strength and mobility.";
      title = "Physiotherapy Equipment";
      image = physiotherapy;
      break;
    case "electrosurgery":
      description = "Surgical technique that uses electrical current to cut, coagulate, or remove tissue during surgery.";
      title = "Electrosurgery Machine";
      image = electrosurgery;
      break;
    case "lifesupport":
      description = "Equipment and medical interventions used to sustain or support the life of a critically ill or injured patient.";
      title = "Life Support Machine";
      image = lifesupport;
      break;
    case "Electrocardiography":
      description = "Device used to record the electrical activity of the heart over a period of time.";
      title = "Electrocardiography Machine";
      image = electrocardiography;
      break;
    case "tables":
      description = "Specialized tables used in medical procedures to support patients in various positions.";
      title = "Procedure tables";
      image = tables;
      break;
    case "infusion":
      description = "Medical device used to deliver fluids, medications, or nutrients to a patient's body in controlled amounts.";
      title = "Infusion Pump";
      image = infusion;
      break;
    case "oxygen":
      description = "Container used to store and transport oxygen for medical purposes.";
      title = "Oxygen Tank";
      image = oxygen;
      break;
      case "stretcher":
      description = "A medical device used for transporting patients who are unable to walk or need to be moved safely.";
      title = "Stretcher";
      image = stretcher;
      break;
    default:
      return null;
  }



  return (
    <Fade>
      <div className={styles.Product}>
        <img className={styles.media} src={image} alt="product" />
        <div className={styles.ProductDescription}>
          <h1>{title}</h1>
          <p>{description}</p>
          <Link to="/contact-us">
            <Button primary>Get a Quote</Button>
          </Link>
        </div>
      </div>
    </Fade>
  );
}

export default Product;
